import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';



const AppbarDesign = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  return (
    <div>
   
      <Hidden mdDown >  
      {/* <Button onClick={()=> navigate('/')} color='inherit'>{<HomeIcon />}</Button> */}
      </Hidden> 
      <Button 
        color='inherit'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        sx={{"&:hover": {borderRadius:1,backgroundColor: '#f3f0f030'}}}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>{<AccountCircleIcon/>}</Button>
        <Menu
        
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
       
      >
        
        {/* <MenuItem  onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem onClick={() => { handleClose(); navigate('/')}}>Logout</MenuItem>
      </Menu>
      </div>
  )
}

export default AppbarDesign