import React from 'react'
//import JsonData from "./Data.json"

export default function IpCount(props) {
    const Data=props.res;
    const DispData = Data?.map((data) => {
        return (
            <tr>
                <td>{data.key}</td>
                <td>{data.doc_count}</td>
            </tr>
        )
    })
    return (
        <>
            <table className='table table-responsive ' style={{backgroundColor: '#1d2939', color: "#ffffff" }} >
                <thead>
                    <tr>
                        <th colSpan={2}><p>{props.title}</p></th>
                    </tr>
                    <tr>
                        <th>IP</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {DispData}
                </tbody>
            </table>
        </>
    )
}
