import React, { useState, useEffect } from 'react'
import axios from 'axios'

function HPStatus(props) {
  // console.log(props);

  const [Status, setStatus] = useState("");

  useEffect(() => {
    const getStatus = async () => {
      let res = await axios.get(`/status?ip=${props.ip}`)
      // console.log(res.data);
      setStatus(res.data);
    }
    if (props.status !== "online") {
      // console.log(props.status.charAt(0));
      setStatus(props.status.charAt(0).toUpperCase() + props.status.substring(1));
    } else {
      getStatus();
    }

    return () => {

    }
  }, [])


  return (
    <>
      {Status === "" && "Loading..."}
      {Status === "Up" && <p style={{ color: 'green', margin: '0px' }}>Online</p>}
      {Status === "Down" && <p style={{ color: 'red', margin: '0px' }}>Offline</p>}
      {!["", "Up", "Down"].includes(Status) && <p style={{ color: 'red', margin: '0px' }}>{Status}</p>}
    </>
  )
}

export default HPStatus