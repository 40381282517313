import React, { useEffect, useState } from 'react'

// let data = JsonData.aggregations
export default function Count(props) {

    const [oneHour, setOneHour] = useState();
    // const [ip, setIp] = useState();
    // const [country, setCountry] = useState();
    // const [port, setPort] = useState();

    const data = props.res;
    // console.log(data)
    async function fetchHoneypot() {
        try {
            if (data) {

                let oneHourSum = 0;
                data.map((element, key) => {
                    let temp = element?.aggregations?.attackCount?.buckets?.['Last Hour'].doc_count;
                    if (temp !== undefined) {
                        oneHourSum += temp
                    }
                })
                setOneHour(oneHourSum)
            }

            // let Ipsum = 0;
            // data.map((element, key) => {
            //     let temp = element?.aggregations?.uniqueSourceIps?.value;
            //     if (temp !== undefined) {
            //         Ipsum += temp
            //     }
            // })
            // setIp(Ipsum)

            // let CountrySum = 0;
            // data.map((element, key) => {
            //     let temp = element?.aggregations?.uniqueSourceCountry?.value;
            //     if (temp !== undefined) {
            //         CountrySum += temp
            //     }
            // })
            // setCountry(CountrySum)

            // let PortSum = 0;
            // data.map((element, key) => {
            //     let temp = element?.aggregations?.groupByDestPort?.buckets['0']['key'];
            //     // return element["aggregations"]["attackCount"]["buckets"]["Last Hour"].doc_count
            //     console.log(temp);
            //     if (temp !== undefined) {
            //         PortSum += temp
            //     }
            // })
            // setPort(PortSum)

        } catch (err) {
            console.log(err);
        }

    }
    useEffect(() => {
        fetchHoneypot()
    }, [data]);


    return (
        <>
            {/* <div className='col-md justify-content-center row countbox'>
                <div className='row'>
                    <h3 style={{color: '#fc5908'}}>{data?.aggregations?.attackCount?.buckets?.['Last 24 Hours'].doc_count}</h3>
                </div>
                <div className='row'>
                    <h5>Event Count</h5>
                    <p>Last 24 Hours</p>
                </div>
            </div> */}
            <div className='col-md justify-content-center row countbox' style={{ backgroundColor: '#1d2939' }} >
                <div className='row'>
                    <h3 style={{ color: '#fc5908' }}>{oneHour}</h3>
                </div>
                <div className='row'>
                    <h5>Event Count</h5>
                    <p>Last 1 Hour</p>
                </div>
            </div>
            {/* <div className='col-md justify-content-center row countbox'>
                <div className='row'>
                    <h3 style={{ color: '#fc5908' }}>{ip}</h3>
                </div>
                <div className='row'>
                    <h5>Unique Source IPs</h5>
                </div>
            </div>
            <div className='col-md justify-content-center row countbox'>
                <div className='row'>
                    <h3 style={{ color: '#fc5908' }}>{country}</h3>
                </div>
                <div className='row'>
                    <h5>Unique Source Countries</h5>
                </div>
            </div>
            <div className='col-md justify-content-center row countbox'>
                <div className='row'>
                    <h3 style={{ color: '#fc5908' }}>{port}</h3>
                </div>
                <div className='row'>
                    <h5>Most Targeted Port</h5>
                </div>
            </div> */}

        </>
    )
}
