import React, { useState, useEffect } from "react";
import { Modal } from '@mui/material'
import IpCount from "./IpCount";

export default function AlertTable(props) {

  const [open, setopen] = useState(false);
  const [alert, setalert] = useState(null);
  const [IPList, setIPList] = useState([]);

  useEffect(() => {
    const fetchAlert = async () => {
      if (alert === null) return;
      const data1 = {
        query: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    "@timestamp": {
                      gte: "now-24h",
                    },
                  },
                },
                {
                  term: {
                    "rule.name": alert.key,
                  },
                },
              ],
            },
          },
          aggs: {
            ip_list: {
              terms: {
                field: "source.address",
                size: props.res.aggregations.uniqueSourceIps.value,
              },
            },
          },
        },
        ip: props.res.ip,
      };
      const response = await fetch(`/search1`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1) });
      const data = await response.json();
      setIPList(data.aggregations.ip_list.buckets);
    }
    fetchAlert();
    return () => {
      
    }
  }, [alert])
  

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    background: '#222',
    border: '2px solid #000',
    boxShadow: 24,
    color: 'white',
    padding: '10px',
    borderRadius: '8px',
    background: '#101828'
  };

  return (
    <>
    <Modal
      open={open}
      onClose={() => { setopen(false); setalert(null); setIPList([]); }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
        <div className="d-flex flex-column" style={{height: '100%', 'width': '100%'}}>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h5><b>Alert Signature:</b> {alert?.key}</h5>
              <h6><b>Category:</b> {alert?.category.buckets[0].key}</h6>
            </div>
            <div className='col-md justify-content-center row countbox' style={{maxWidth: '200px', background: '#1d2939' }}>
                <div className='row'>
                    <h3 style={{color: '#ffffff'}}>{alert?.doc_count}</h3>
                </div>
                <div className='row' style={{color: '#ffffff'}} >
                    <p>Last 24 Hours</p>
                </div>
            </div>
          </div>
          <hr/>
          <div style={{height: '100%', overflowY: 'auto'}}>
            <IpCount res={IPList} title="All IP Associated"/>
          </div>
        </div>
      </div>
    </Modal>
      <table className="table table-responsive" style={{backgroundColor: '#1d2939', color: "#ffffff" }} >
        <thead>
          <tr>
            <th colSpan={3}><p>Alert Logs</p></th>
          </tr>
          <tr>
            <th>Alert Signature</th>
            <th>Alert Category</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
            {
                props?.res?.aggregations?.groupByAlertRule?.buckets.map((data, index) => {
                    return(
                        <tr onClick={() => { setalert(data); setopen(true); }} key={index}>
                            <td>{data.key}</td>
                            <td>{data.category.buckets[0].key}</td>
                            <td>{data.doc_count}</td>
                        </tr>
                    );
                })
            }
            {
              props?.res?.aggregations?.groupByAlertRule?.buckets.length === 0 && <tr><td colSpan={3}>No Record Found</td></tr>
            }
        </tbody>
      </table>
    </>
  );
}
