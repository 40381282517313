
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { LeftbarData } from './leftbarData'
import { useNavigate } from 'react-router-dom'
import "../../../../index.css"


const LeftbarDesign = () => {
  
  const navigate=useNavigate();
  return (
    <div>
        {
            LeftbarData.map(item=>(
                <ListItem
                sx={{color:'var(--buttoncolor)', "&:hover": {
                  borderRadius:1,
                  color: 'gray',
                  backgroundColor: '#f3f0f030'}}}
                 button
                 key={item.id}
                 onClick={()=> navigate(item.path)}
                 >
                  
                    <ListItemIcon sx={{color:'white'}}>{item.icon}</ListItemIcon>
                    <ListItemText sx={{color:'white'}}>{item.title}</ListItemText>
                   
                </ListItem>
                
            ))
            
        }
        
    </div>
  )
}

export default LeftbarDesign