import React from 'react'
import '../Dashboard.css'


// let data = JsonData.aggregations
export default function Count(props) {
    const data=props.res;
    // console.log(data)
    return (
        <>
            <div className='col-md justify-content-center row countbox' style={{ backgroundColor: '#1d2939'}} >
                <div className='row'>
                    <h3 >{data?.aggregations?.attackCount?.buckets?.['Last 24 Hours'].doc_count}</h3>
                </div>
                <div className='row'>
                    <h5 style={{ color: '#fff' }} >Event Count</h5>
                    <p style={{ color: '#fff' }} >Last 24 Hours</p>
                </div>
            </div>
            <div className='col-md justify-content-center row countbox' style={{ backgroundColor: '#1d2939' }} >
                <div className='row'>
                    <h3>{data?.aggregations?.attackCount?.buckets?.['Last Hour'].doc_count}</h3>
                </div>
                <div className='row'>
                    <h5 style={{ color: '#fff' }} >Event Count</h5>
                    <p style={{ color: '#fff' }} >Last 1 Hours</p>
                </div>
            </div>
            <div className='col-md justify-content-center row countbox' style={{backgroundColor: '#1d2939'}} >
                <div className='row'>
                    <h3 style={{ color: '#fff' }}>{data?.aggregations?.uniqueSourceIps?.value}</h3>
                </div>
                <div className='row'>
                    <h5 style={{ color: '#fff' }} >Unique Source IPs</h5>
                </div>
            </div>
            <div className='col-md justify-content-center row countbox' style={{backgroundColor: '#1d2939'}} >
                <div className='row'>
                    <h3>{data?.aggregations?.uniqueSourceCountry?.value}</h3>
                </div>
                <div className='row'>
                    <h5 style={{ color: '#fff' }} >Unique Source Countries</h5>
                </div>
            </div>
            <div className='col-md justify-content-center row countbox' style={{backgroundColor: '#1d2939'}} >
                <div className='row'>
                    <h3 style={{color: '#fff'}}>{(data?.aggregations?.groupByDestPort?.buckets.length) ? data?.aggregations?.groupByDestPort?.buckets['0']['key'] : '--'}</h3>
                </div>
                <div className='row'>
                    <h5 style={{color: '#fff'}} >Most Targeted Port</h5>
                </div>
            </div>

        </>
    )
}
