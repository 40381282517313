import React, { useState } from 'react'
import { Grid, Avatar, TextField, Button } from '@mui/material'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import '../login/login.css';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import logo from '../../images/logo.png';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)


    const loginChange = async (e) => {
        setLoading(true);
        e.preventDefault();

        const res = await fetch('/signin', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email, password
            })
        });

        const data = await res.json();

        if (res.status === 400 || !data) {
            window.alert("Failed request");
            setLoading(false);
        } else {
            // window.alert("success");
            setLoading(false);
            navigate("/homepage");
        }
    }




    const paperStyle = { padding: 15, width: 360 }
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const btnstyle = { backgroundColor: '#8838DD', margin: '8px 1' }
    return (
        <>
            <form method='POST'>
                <div className='main' >
                    <div className="login" style={{backgroundColor: '#1d2939', color: "#ffffff" }} >
                        <div style={paperStyle}>
                            <Grid align='center' style={{ marginBottom: 40}}>
                                {/* <div className='avatar'>
                                    <Avatar style={avatarStyle}><LockOpenOutlinedIcon /></Avatar>
                                </div>
                                <h2>Sign In</h2> */}
                                <img height={80} src={logo}/>
                                <br/>
                                <br/>
                                <h2>Welcome</h2>
                            </Grid>
                            <ThemeProvider theme={darkTheme}>
                                <CssBaseline/>
                                <TextField className="email" name='email' label='Email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)} fullWidth required />
                                <TextField className="password" name='password' label='Password' placeholder='Enter password' value={password} onChange={(e) => setPassword(e.target.value)} type='password' margin='normal' fullWidth required />
                                {/* <TextField className="token" name='token' label='Token' placeholder='Enter token' value={password}  type='token' margin='normal' fullWidth required /> */}
                            </ThemeProvider>
                            <Button className='signupbutton'  type='submit' color='success' variant="contained" onClick={loginChange} style={btnstyle} fullWidth> { loading ? <CircularProgress color="inherit" />
                            : <div>Sign In</div> }</Button>
                        </div>
                    </div>
                </div>
            </form>
        </>

    )
}

export default Login