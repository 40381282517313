import './App.css';
import Sidebar from './components/Header/Layout/Sidebar';
import "./index.css"

import Server from './components/Header/pages/server/Server';
import About from './components/Header/pages/about/about';
import {Routes,Route,BrowserRouter, Outlet} from "react-router-dom"
import Login from './components/login/login';
import Network from './components/Network/Network';
import Dashboard from './components/Dashboard/Dashboard';
import Home from './components/Home/Home'
import AlertLog from './components/AlertLog/AlertLog';
import Deploy from './components/Deploy_Honeypot/Deploy'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


const SidebarLayout=()=>(
  <>
  <Sidebar/>
  <div className="routeContainer">
    <Outlet/>
  </div>
  </>
)

 function App() {
    return (
     
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <BrowserRouter>
          <div className="bg-black">
            <div className="bg-honeycomb">
              <Routes>
                <Route  element={<SidebarLayout/>}>
                <Route exact path='/server' element={<Server/>}/>
                <Route exact path='/home' element={<Network/>}/>
                <Route exact path='/about' element={<About/>}/>
                <Route exact path='/dashboard/:id' element={<Dashboard/>}/> 
                <Route exact path='/dashboard' element={<Dashboard/>}/>
                <Route exact path='/homepage' element={<Home/>}/>
                <Route exact path='/alertlog' element={<AlertLog/>}/>
                <Route exact path='/deploy' element={<Deploy/>}/>
                </Route>
              <Route exact path="/" element={<Login/>}/>
              </Routes>
            </div>
          </div>
        </BrowserRouter>        
      </ThemeProvider>
    );
  }


export default App;