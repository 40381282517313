import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "./Dashboard.css";
import DataTable from "./DashboardComp/Table.js";
import AlertTable from "./DashboardComp/AlertTable";
import Map from "./DashboardComp/Map.js";
import Bar from "./DashboardComp/Bar.js";
import Count from "./DashboardComp/Count.js";
import CountryCount from "./DashboardComp/CountryCount";
import IpCount from "./DashboardComp/IpCount";
import Query from "./DashboardComp/Query";
import ExportQuery from "./DashboardComp/ExportQuery";
import CircularProgress from '@mui/material/CircularProgress';
import { Download } from '@mui/icons-material';
import { Button } from '@mui/material'

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const dt = location.state;
  const [user1, setUser1] = useState();
  useEffect(() => {
    // console.log('Test', location);
    const data = async () => {

      const data1 = { 'query': Query, 'ip': dt.ip, 'body': dt }
      const response = await fetch(`/search1`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1) });
      const data = await response.json();

      setUser1({ ...data, 'ip': dt.ip });
    };
    if (location.state) {
      data();
    } else {
      alert("Error loading data");
      navigate('/home')
    }
    // data();
  }, []);

  const handleExport = async () => {
    const data = { 'query': ExportQuery, 'ip': dt.ip, 'body': dt }
    const response = await fetch(`/search1`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) });
    const jsonData = await response.json();
    console.log(jsonData);
    var exportdata = new Blob([JSON.stringify(jsonData.hits.hits, null, 2)], { type: 'text/json' });
    var csvURL = window.URL.createObjectURL(exportdata);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `export-${dt.ip}-${new Date().getTime()}.json`);
    tempLink.click();
  }


  return (
    <div style={{ border: '18px solid #1d2939', borderRadius: '24px' }} >
      {!user1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '87vw', height: '85vh' }}>
        <CircularProgress color="success" />
      </div>}
      {user1 && <div>
        {/* <div className="d-flex justify-content-around text-center">
          <p>IP : { dt.ip }</p>
          <p>ID : { dt._id }</p>
          <p>Region : { dt.region.toUpperCase() }</p>
        </div> */}
        <div className="d-flex justify-content-between align-items-center px-3">
          <div class="two">
            <h1>{dt?.ip}
              <span>{dt?.description || '--'}</span>
              <span className="last">{dt?.name || '--'}</span>
            </h1>
          </div>
          <div>
            <Button style={{backgroundColor: '#8838DD'}} variant="contained" onClick={handleExport}><Download fontSize="large" />Export JSON</Button>
          </div>
        </div>
        <div className="text-center">
          {/* <p>Description: { dt.description }</p> */}
          {/* <Button color={"primary"} variant="contained" onClick={handleExport}><Download fontSize="large" />Export JSON</Button> */}
        </div>
        <div className="row count_strip">
          <Count res={user1} />
        </div>
        <div className="row mx-2" >
          <div className="col-md chart" style={{backgroundColor: '#1d2939' }} >
            <Bar res={user1} />
          </div>
          <div className="col-md chart" style={{backgroundColor: '#1d2939' }} >
            <Map res={user1} />
          </div>
        </div>
        <div className="row table1 my-5">
          <DataTable res={user1} />
        </div>
        <div className="row table1">
          <AlertTable res={user1} />
        </div>
        <div className="row">
          <div className="col-md table1">
            <CountryCount res={user1} />
          </div>
          <div className="col-md table1">
            <IpCount res={user1?.aggregations?.groupBySourceIP?.buckets} title="Top 10 Source IPs" />
          </div>
        </div>
      </div>}
    </div>
  );
}
