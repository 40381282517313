import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Box, StepLabel, Step, Stepper, MenuItem, FormControl, InputLabel, Select } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import area from './region.json'
import { useNavigate } from "react-router-dom";

const steps = [
    'Honeypot Information',
    'Cluster Details'
];

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const btnstyle = { backgroundColor: '#8838DD', margin: '8px 1' }
const btnstyle2 = { position: 'absolute', top: '5', right: '5', cursor: 'pointer', color: '#ffffff' }


const Modall = () => {
    const navigate = useNavigate();

    // console.log(area.regions);

    const [state, setState] = useState(0);
    const [configuration, setConfiguration] = useState([]);
    const [serverData, setServerData] = useState({
        honeypotdetail: "", type: "", slug: "", size: 1
    });
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setServerData({});
        setState(0);
    }

    const honeypotType = ['IT', 'OT'];
    // const size = [1, 2, 3, 4];

    const nextModal = () => {
        if (!serverData.type || !serverData.honeypotdetail) {
            window.alert("Fill the details properly")
        } else {
            setState(state + 1);
        }
    }
    const BackServer = () => {
        setState(state - 1);
    }


    let name, value;
    const handleInput = (e) => {
        // console.log(e);
        name = e.target.name;
        value = e.target.value;
        setServerData({ ...serverData, [name]: value })
    }

    const PostServer = async (e) => {
        try {
            e.preventDefault();
            let { honeypotdetail, type, description, slug, size } = serverData;

            if (!honeypotdetail || !type || !slug) {
                window.alert("Fill the details properly")
            }
            else {
                // Setting description before posting
                const desiredConfig = configuration.find(config => config.name === honeypotdetail);
                // console.log(desiredConfig._id);
                description = desiredConfig.description
                honeypotdetail = desiredConfig._id

                // console.log(honeypotdetail, type, description, slug);

                const res = await fetch(`/honeypots`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        honeypotdetail, type, description, slug, size: 1
                    })
                });

                const data = await res.json();
                // console.log("Data", data);
                window.alert(data.message);
                window.location.reload();

                handleClose();
            }

        } catch (error) {
            console.log(error.response.data.message);
            // if (error.response.status === 401) {
            //     navigate('/')
            // }
        }
    }




    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: '#1d2939',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        color: "#ffffff"
    };

    async function fetchconfig() {
        try {

            const config = await axios.get(`/configuration/list`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // console.log(config);
            setConfiguration(config.data.posts);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchconfig()
    }, [])

    return (
        <>
            <div >
                <Button variant="contained" color="primary" onClick={handleOpen} >
                    <AddIcon fontSize="small" /> Create Server
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <CloseIcon style={btnstyle2} onClick={handleClose} />
                        <ThemeProvider theme={darkTheme}>
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={state} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            {state === 0 &&
                                <>
                                    {/* <Grid align='center'> <h6>Add Server</h6> </Grid> */}

                                    {/* <TextField className="type" name='type' label='Type' placeholder='Enter type' margin='normal' value={serverData.type} onChange={handleInput} fullWidth required /> */}
                                    {/* <TextField className="name" name='name' label='Name' placeholder='Enter name' margin='normal' value={serverData.name} onChange={handleInput} fullWidth required /> */}
                                    <FormControl margin='normal' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            name='type'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={serverData.type}
                                            label="Type"
                                            onChange={handleInput}
                                        >
                                            {honeypotType.map((type) => {
                                                return <MenuItem value={type} key={type} >{type}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl margin='normal' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Honeypot</InputLabel>
                                        <Select
                                            name='honeypotdetail'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={serverData.honeypotdetail}
                                            label="Honeypot"
                                            onChange={handleInput}
                                        >
                                            {configuration
                                                .filter(i => i.type === serverData.type)
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map((config, index) => (
                                                    <MenuItem key={index} value={config.name}>
                                                        {config.name}
                                                    </MenuItem>
                                                ))}

                                        </Select>
                                    </FormControl>
                                    {/* <TextField fullWidth id="fullWidth" className="description" name='description' label='Description' placeholder='Description' margin='normal' value={serverData.description} onChange={handleInput} /> */}
                                    <Button type='submit' color='primary' variant="contained" fullWidth margin='normal' style={btnstyle} onClick={nextModal} >Next</Button>
                                </>}


                            {state === 1 && <>

                                <FormControl margin='normal' fullWidth>
                                    <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                    <Select
                                        name='slug'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={serverData.slug}
                                        label="Honeypot"
                                        onChange={handleInput}
                                    >
                                        {/* {region.map((type) => {
                                        return <MenuItem value={type} >{type}</MenuItem>
                                    })} */}


                                        {area.regions
                                            .filter(type => type.available)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map(type => (
                                                <MenuItem key={type.slug} value={type.slug}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}

                                    </Select>
                                </FormControl>
                                {/* 
                                <FormControl margin='normal' fullWidth>
                                    <InputLabel id="demo-simple-select-label">Size</InputLabel>
                                    <Select
                                        name='size'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={serverData.size}
                                        label="Size"
                                        onChange={handleInput}
                                    >
                                        {size.map((type) => {
                                            return <MenuItem value={type} >{type}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl> */}

                                <Button type='submit' color='primary' variant="contained" style={{ width: '49%', marginRight: '1%', backgroundColor: '#8838DD' }} margin='normal' onClick={BackServer} >Back</Button>
                                <Button type='submit' color='primary' variant="contained" style={{ width: '49%', marginRight: '1%', backgroundColor: '#8838DD' }} margin='normal' onClick={PostServer} >ADD</Button>
                            </>
                            }
                        </ThemeProvider>
                    </Box>
                </Modal>
            </div>


        </>
    )
}

export default Modall