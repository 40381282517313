import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import axios from 'axios'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: '#ffffff',
    border: '2px solid #000',

    boxShadow: 24,
    p: 4,
    color: 'Black',
    backgroundColor: '#1d2939',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
}

const btnstyle = {
    backgroundColor: '#8838DD',
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid white',
    borderRadius: '20px'

}

const boxstyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    margin: '20px',
}

const ActionModal = (props) => {
    const [open, setOpen] = React.useState(false);
    const [state, setstate] = React.useState(null);
    const [id, setid] = React.useState();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setstate(null)
        setid(null)
    }

    function handleChangeModal(value) {
        // console.log(value);
        window.alert("This action is Currently disabled")
        handleClose()
        return ;
        // setstate(value);
    }

    async function handleAction() {
        const headers = {
            "id": id,
            "action": state
        }
        console.log(headers);
        const res = await axios.post('/actions', headers)
        if (res.status === 200) {
            window.alert(res.data.message);
            setOpen(false);
            setstate(null)
            setid(null)
        }
    }

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen} >
                action
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        state ?
                            <div>
                                <Box sx={boxstyle}  >
                                    <TextField className="id" sx={{
                                        backgroundColor: '#ffffff',
                                        border: '2px solid #8838DD',
                                        borderRadius: '10px',
                                        outline: 'none',
                                        '& label.Mui-focused': {
                                            color: 'black',
                                        },
                                    }} name='id' label='ID' placeholder='Enter ID' value={id} onChange={(e) => setid(e.target.value)} />
                                    <Button variant='contained' style={{ backgroundColor: '#8838DD', marginTop: '10px' }} onClick={handleAction} >{state}</Button>
                                </Box>
                            </div> :
                            <>
                                <Box sx={boxstyle} >
                                    <Button style={btnstyle} onClick={() => handleChangeModal("shutdown")} variant="contained" >
                                        <PowerSettingsNewIcon style={{ fontSize: 100, color: '#fff' }} />
                                        <Box >Power Off</Box>
                                    </Button>
                                </Box>
                                <Box sx={boxstyle} >
                                    <Button style={btnstyle} onClick={() => handleChangeModal("reboot")} variant="contained">
                                        <RestartAltRoundedIcon style={{ fontSize: 100, color: '#fff' }} />
                                        <Box >Restart</Box>
                                    </Button>
                                </Box>
                                <Box sx={boxstyle} >
                                    <Button style={btnstyle} onClick={() => handleChangeModal("delete")} variant="contained">
                                        <DeleteRoundedIcon style={{ fontSize: 100, color: '#fff' }} />
                                        <Box >Delete</Box>
                                    </Button>
                                </Box>
                            </>
                    }
                </Box>



            </Modal>


        </>
    )
}

export default ActionModal