import React from 'react'
import './Error.css'

const Error = () => {
    return (
        <div className='error' >
            <h4>Opps! Record not found</h4>
        </div>
    )
}

export default Error