import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Hidden } from '@mui/material';
import LeftbarDesign from './SidebarComponents/leftbarDesign';
import AppbarDesign from './SidebarComponents/appbarDesign';
import BottomBar from './SidebarComponents/bottombar'
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import "../../../index.css"
import logo1 from "../../../images/CyfirmaLogoWhite.svg";

const drawerWidth = 240;




// Drawer style


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background:'#101828',
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background:'#101828',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
   
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



// Appbar style



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



export default function Sidebar({children}) {
  const location=useLocation()
  const navigate=useNavigate()
 
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{display: 'flex',m:0,p:0}} >
      <CssBaseline />

    {/* Appbar JSX */}

      <AppBar position="fixed" open={open} sx={{background:'#101828'}}>
        <Toolbar>
        <Hidden  lgDown>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{"&:hover": {borderRadius:1,backgroundColor: '#f3f0f030'},
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
          <MenuIcon />
          </IconButton>
          </Hidden>
          <img src={logo1}/>
          <Typography variant="h6" noWrap component="div" sx={{flexGrow:1, marginLeft: 5}}>
          {location.pathname.replace("/login/",'').replace('/', '').replace("/", " / ").toUpperCase()}
          {/* consol.log{location.pathname.replace("/login/",'')} */}
          </Typography>
          
     

      <AppbarDesign/>  {/* Appbar design */}
        </Toolbar>
         </AppBar>

       {/* Drawer JSX */}

      <Hidden  mdDown>
      <Drawer variant="permanent" open={open}  >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{color:'var(--buttoncolor)',"&:hover": {borderRadius:1,backgroundColor: '#f3f0f030'}}}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}

          </IconButton>
        </DrawerHeader>
        <Divider />
        <LeftbarDesign sx={{color:'var(--buttoncolor)'}}/>
        <Divider sx={{color:'white',bgcolor:'white',m:2,}}/>
        <Button onClick={()=> navigate('/')}
                sx={{color:'var(--buttoncolor)',"&:hover": { borderRadius:1,backgroundColor: '#f3f0f030'}}}
                lable='Logout'>{<LogoutIcon/>}
        </Button>
        
      </Drawer>
      </Hidden>

    {/* BottomBar JSX */}

      <Hidden mdUp>  
      <BottomBar /> 
      </Hidden>
      
      
    </Box>
  
  );
}
