import { React, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
// import JsonData from "./Table.json";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Pagination } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, Select, Modal } from '@mui/material'
import { Download } from "@mui/icons-material";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function Table(props) {

  const [page, setPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [fetchData, setfetchData] = useState([]);
  const [dataType, setDataType] = useState(["alert", "event"]);
  const [type, setType] = useState("All");
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const location = useLocation();
  const dt = location.state;

  var FetchQuery = {
    "size": 10,
    "from": page * 10,
    "query": {
      "bool": {
        "filter": [
          { 
            "range": { 
              "@timestamp": { 
                "gte": "now-24h" 
              } 
            } 
          },
          {
            "terms": {
              "event.kind": dataType
            }
          }
        ]
      }
    },
    "sort": [
      {
        "@timestamp": {
          "order": "desc"
        }
      }
    ]
  };

  const data = async () => {
    const data1 = { 'query': FetchQuery, 'ip': dt.ip, 'body': dt }
    const response = await fetch(`/search1`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1) });
    const data = await response.json();
    setfetchData(data)
    var PageCount = Math.round(data.hits.total.value/10);
    settotalPages(PageCount);
  }

  //datatype
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = (e) => {
  //   setAnchorEl(null);
  // };

  const getValue = (e) => {
    var value = e.target.getAttribute('value')
    var a = [];
    if (value === 'all') {
      a = ["alert", "event"] 
    }
    else if (value === 'alert'){
      a = ["alert"]
    }
    else{
      a = ["event"]
    }
    setPage(1);
    setDataType(a)
    // handleClose()
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    background: '#222',
    border: '2px solid #000',
    boxShadow: 24,
    color: 'white',
    padding: '10px',
    borderRadius: '8px',
    background: '#101828'
  };

  const handleChange = (e) => {
    setType(e.target.value);

    var value = e.target.value.toLowerCase();
    var a = [];
    if (value === 'all') {
      a = ["alert", "event"] 
    }
    else if (value === 'alert'){
      a = ["alert"]
    }
    else{
      a = ["event"]
    }
    setPage(1);
    setDataType(a);
  }

  const handleExport = () => {
    var exportdata = new Blob([JSON.stringify(JSON.parse(selectedData?._source?.event?.original), null, 4)], {type: 'text/json'});
    var csvURL = window.URL.createObjectURL(exportdata);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `export-raw-event-${dt.ip}-${selectedData?._id}.json`);
    tempLink.click();
  }


  useEffect(() => {
    data()
  }, [page, dataType]);

  const Data = fetchData;
  const DispData = Data?.hits?.hits?.map((data) => {
    return (
      <tr key={data._id} onClick={() => { setSelectedData(data); setOpen(true);}}>
        <td>{new Date(data._source['@timestamp']).toUTCString()}</td>
        {/* <td>{data._score}</td> */}
        {/* <td>{data._source.agent.hostname}</td> */}
        {/* <td>{data._source.agent.id}</td> */}
        {/* <td>{data._source.agent.type}</td> */}
        {/* <td>{data._source.source.geo.continent_name}</td> */}
        <td>{data._source.event.kind.toUpperCase()}</td>
        <td>{data._source.destination.port}</td>
        <td>{data._source.source.address}</td>
        <td><span className={`fi fi-${data._source.source?.geo?.country_iso_code.toLowerCase()}`}></span>&nbsp;{data._source.source?.geo?.country_iso_code || '--'}</td>
        {/* <td>{data._source.source.port}</td> */}
        {/* <td>{data._source.source.geo.city_name}</td> */}
        <td>{data._source.source?.geo?.region_name || '--'}</td>
        {/* <td>{data._source.event.kind}</td> */}
        {/* <td>{data._source.source.geo.location.lat}</td> */}
        {/* <td>{data._source.source.geo.location.lon}</td> */}
        {/* <td>{data._source.source.bytes}</td> */}
      </tr>
    );
  });
  return (
    <>
    <Modal
      open={open}
      onClose={() => { setSelectedData(null); setOpen(false); }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
        <div style={{height: '10%'}} className="d-flex align-items-center justify-content-between">
          <div>
            <h5><b>Event ID:</b> {selectedData?._id}</h5>
            <h6><b>Category:</b> {selectedData?._source.event.kind.toUpperCase()}</h6>
          </div>
          <Button style={{backgroundColor: '#1d2939',  backgroundColor: "#8838DD"  }} variant="contained" onClick={handleExport}><Download fontSize="large" />Export Log</Button>
        </div>
        <hr/>
        <div style={{height: '85%'}}>
          <h6>Raw Log</h6>
          <pre style={{height: '95%', overflowY: 'auto'}}>
            <code>
              {selectedData && JSON.stringify(JSON.parse(selectedData?._source?.event?.original), null, 4)}
            </code>
          </pre>
        </div>
      </div>
    </Modal  >
      <div className="d-flex underline justify-content-between align-items-center" style={{backgroundColor: '#1d2939' }} >
        <h3>Event Logs</h3>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Select
            id="select"
            value={type}
            label="Type"
            onChange={handleChange}
            style={{ width: 100}}
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'Event'}>Event</MenuItem>
            <MenuItem value={'Alert'}>Alert</MenuItem>
          </Select>
        </ThemeProvider>
      </div>
      <table className="table table-responsive " style={{backgroundColor: '#1d2939', color: '#ffffff' }} >
        <thead>
          <tr>
            <th>Timestamp</th>
            {/* <th>Score</th>
                        <th>Host Name</th>
                        <th>Source ID</th>
                        <th>Source Type</th>
                        <th>Continent</th> */}
            <th>Type</th>
            <th>Port</th>
            <th>Source IP</th>
            <th>Source Country Code</th>
            {/* <th>Port</th> */}
            {/* <th>City</th> */}
            <th>Region</th>
            {/* <th>Type</th> */}
            {/* <th>Lat</th> */}
            {/* <th>Lon</th> */}
            {/* <th>Bytes</th> */}
          </tr>
        </thead>
        <tbody>{DispData}</tbody>
      </table>
      <div className="pagination">
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Pagination
            page={page}
            size="large"
            count={totalPages}
            color="secondary"
            variant='outline'
            shape='rounded'
            onChange={(event, value) => setPage(value)}
          />


          {/* <Button
            sx={{ position: "relative", left: "30%" }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            TYPE
          </Button>
          <Menu
            elevation={1}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem value='all' onClick={getValue}>All</MenuItem>
            <MenuItem value="alert" onClick={getValue}>Alert</MenuItem>
            <MenuItem value="event" onClick={getValue}>Event</MenuItem>
          </Menu> */}
        </ThemeProvider>

      </div>
    </>
  );
}
