import React from 'react'
//import JsonData from "./Data.json"
export default function CountryCount(props) {
    let dataArray = [];
    const combinedArray = [];
    async function mapData() {
        try {
            if (props.res) {
                for (let i = 0; i < props.res.length; i++) {
                    // console.log(props.res[i].aggregations);
                    if (props.res[i].aggregations !== null) {
                        let obj = props.res[i]?.aggregations?.groupByCountry?.buckets;
                        // console.log(obj);
                        if (obj !== undefined) {
                            for (let j = 0; j < Object.keys(obj).length; j++) {
                                dataArray.push(obj[j])
                            }
                        }
                    }

                }
                dataArray.forEach(item => {
                    const existingItem = combinedArray.find(element => element.key === item.key);

                    if (existingItem) {
                        existingItem.doc_count += item.doc_count;
                    } else {
                        combinedArray.push({ key: item.key, doc_count: item.doc_count });
                    }
                });
                combinedArray.sort((a, b) => b.doc_count - a.doc_count);
                // console.log(combinedArray);
            }
        } catch (err) {
            console.log(err);
        }

    }
    mapData()
    // console.log(combinedObj);
    const DispData = combinedArray.slice(0, 10).map((data) => {
        return (
            <tr key={data.key}>
                <td><span className={`fi fi-${data.key.toLowerCase()}`}></span>&nbsp;{data.key}</td>
                <td>{data.doc_count}</td>
            </tr>

        )
    })
    return (
        <>
            <table className='table table-responsive' style={{ backgroundColor: '#1d2939', color: "#ffffff" }} >
                <thead>
                    <tr>
                        <th colSpan={2}><p>Top 10 Source Countries</p></th>
                    </tr>
                    <tr>
                        <th>Country Code</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {DispData}
                </tbody>
            </table>
        </>
    )
}
