import React, { useState } from 'react'
import { Grid, TextField, Button } from '@mui/material'
import { Pagination } from '@mui/material';
import './Network.css'
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import Error from "../Error/Error"
import Modall from "./Modall"
import ActionModal from './ActionModal';

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HPStatus from './HPStatus';
import axios from 'axios';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const columns = [
  { id: "Name", label: "Name" },
  { id: "Ip", label: "Ip" },
  { id: "Type", label: "Type" },
  { id: "Region", label: "Region" },
  { id: "Action", label: "Action" }
];

const Network = () => {

  const [honeypot, setHoneypot] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setsearchQuery] = useState(null);
  const [totalPages, settotalPages] = useState(10)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();


  // fetch the data of honeypot according to pagination 
  async function fetchHoneypot() {
    try {
      setLoading(true);
      const response = await fetch(`/honeypots?page=${page}&limit=8`);
      const data = await response.json();
      data.posts.forEach(hp => (
        { ...hp, status: "" }
      ));
      setHoneypot(data.posts);
      settotalPages(data.totalPages);
      setLoading(false);
    } catch (err) {
      console.log(err);
      navigate("/");
    }

  }

  //search query for name of honeypot
  async function search() {
    setLoading(true);
    const response = await fetch(`/searchHoneypot/?page=${page}&limit=8&id=${searchQuery}`);
    const data = await response.json();
    data.posts.forEach(hp => (
      { ...hp, status: "" }
    ));
    setHoneypot(data.posts);
    // console.log(data.totalPages);
    settotalPages(data.totalPages);
    // setLoading(true);
    setLoading(false);
  }

  function find() {
    if (page !== 1) {
      setPage(1);
    } else {
      search();
    }
  }
  //get the value from input box and set it to searchquery state 
  function getData(e) {
    setsearchQuery(e.target.value);
  }

  async function handleDashboardOpen(current) {
    if (current.status === "Setting Up") {
      window.alert("Server is currently Setting Up please wait for 10 minutes")
    }
    else {
      let res = await axios.get(`/status?ip=${current.ip}`)
      if (res.data.toLowerCase() !== "up") {
        window.alert("Server is currently shutdown please restart to view Analytics")
      } else {
        navigate(`/dashboard/${current._id}`, { state: current })
      }

    }
  }

  useEffect(() => {
    let pg = window.localStorage.getItem("page");
    if (pg) {
      setPage(+pg);
      // console.log(+pg);
    } else {
      setPage(1);
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem("page", page);
    if (searchQuery !== null && searchQuery !== '') {
      search();
    }
    else {
      fetchHoneypot();
    }
  }, [page]);


  // useEffect(() => {
  //   if (searchQuery === '' || searchQuery === null) {

  //     if (page === 1) {
  //       fetchHoneypot();
  //     }
  //     else {
  //       setPage(1);
  //     }
  //   }
  // }, [searchQuery]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="netowrk">
        <div className="heading">
          <h2>Honeypots</h2>
        </div>

        <div className='search'>
          {/* <input type="text" name='name' className='searchInput' label='Search Server' placeholder='Search Server' onChange={getData} /> */}
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <TextField className="searchInput" name='name' label='Search Server' placeholder='Search Server' variant="standard" size="small" onChange={getData} />
          </ThemeProvider>
          <Button onClick={find} ><SearchIcon fontSize="large" color="primary" /></Button>
          <Modall />
        </div>

        <section style={{ paddingRight: '15px' }} >
          {!loading ?
            <div>
              {false && <Paper sx={{ width: "95%", overflow: "hidden", marginLeft: "2.5vw", marginTop: "1.5vh", marginBottom: "1vh" }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table"  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            sx={{ fontSize: "1.2rem", minWidth: "150px", padding: "14px" }}
                            key={column.id}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>{
                      honeypot.map((current) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell key={current.name} sx={{ fontSize: "1rem", padding: "10px" }} > {current.name} </TableCell>
                          <TableCell key={current.ip} sx={{ fontSize: "1rem", padding: "10px" }} > {current.ip} </TableCell>
                          <TableCell key={current.type} sx={{ fontSize: "1rem", padding: "10px" }} > {current.type} </TableCell>
                          <TableCell key={current.region} sx={{ fontSize: "1rem", padding: "10px" }} > {current.region.toUpperCase()} </TableCell>
                          {/* <TableCell > <Link to={`/login/dashboard/${current._id}`} state={current}>Click</Link> </TableCell> */}
                          <TableCell sx={{ padding: "10px" }} >
                            <Button
                              id="basic-button"
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleClick}
                            >
                              Options
                            </Button>
                            <Menu
                              elevation={1}
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem onClick={handleClose}><Link to={`/dashboard/${current._id}`} state={current} style={{ textDecoration: 'none', color: 'black' }}>Dashboard</Link></MenuItem>
                              <MenuItem onClick={handleClose}>Delete</MenuItem>
                              <MenuItem onClick={handleClose}>Etc</MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>

                      ))
                    }

                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>}
              <table className="table table-responsive mx-2 my-3" style={{ backgroundColor: '#1d2939', color: "#ffffff" }} >
                <thead>
                  <tr>
                    <th style={{ width: '40%' }}>Name</th>
                    <th>IP Address</th>
                    <th>Type</th>
                    <th>Region</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {honeypot.map((current, index) => (
                    <tr key={index} >
                      <td onClick={() => handleDashboardOpen(current)} >{current.name.toUpperCase()}</td>
                      <td onClick={() => handleDashboardOpen(current)} >{current.ip}</td>
                      <td onClick={() => handleDashboardOpen(current)} >{current.type}</td>
                      {
                        current.region === 'nyc3' ?
                          <td onClick={() => handleDashboardOpen(current)} >MEA 1 </td>
                          :
                          <td onClick={() => handleDashboardOpen(current)} >{current.region.toUpperCase()} </td>
                      }
                      <td  ><HPStatus ip={current.ip} status={current.status.toLowerCase()} /></td>
                      <td>
                        <ActionModal id={current._id} name={current.name} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            :
            <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '87vw', height: '' }}>
              <CircularProgress color="success" />
            </Grid>
          }

          {(honeypot.length === 0 && (!loading)) && <Error />}

        </section>
        {
          (honeypot.length !== 0 && (!loading)) &&
          <div className="pagination">
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <Pagination
                page={page}
                size="large"
                count={totalPages}
                color="secondary"
                variant='outline'
                shape='rounded'
                onChange={(event, value) => setPage(value)}
              />
            </ThemeProvider>
          </div>

        }


      </div>
    </>
  )
}

export default Network