const Query = { 
  "query": { 
    "bool": { 
      "filter": [ 
        { 
          "range": { 
            "@timestamp": { 
              "gte": "now-24h" 
            } 
          } 
        },
        {
          "terms": {
            "event.kind": ["alert", "event"]
          }
        }
      ]
    } 
  },
  "sort": [
    {
      "@timestamp": {
        "order": "desc"
      }
    }
  ], 
  "aggregations": { 
    "groupByCountry": { 
      "terms": { 
        "field": "source.geo.country_iso_code",
        "size": 100
      }
    },
    "uniqueSourceCountry": {
      "cardinality": {
        "field": "source.geo.country_iso_code"
      }
    },
    "groupByDestPort": {
      "terms": {
        "field": "destination.port"
      }
    },
    "groupByAlertRule": {
      "terms": {
        "field": "suricata.eve.alert.signature",
        "order":{"_count":"desc"},"size":15
      },
      "aggs": {"category":{"terms":{"field":"suricata.eve.alert.category","order":{"_count":"desc"},"size":5}}}
    },
    "groupBySourceIP": {
      "terms": {
        "field": "source.address"
      }
    },
    "uniqueSourceIps": {
      "cardinality": {
        "field": "source.address"
      }
    },
    "barChartData": { 
      "date_histogram": { 
        "field": "@timestamp", 
        "fixed_interval": "1h" 
      } 
    },
    "attackCount": {
      "range": {
        "field": "@timestamp",
        "ranges": [
          {
            "key": "Last Hour", 
            "from": "now-1h",
            "to": "now"
          },
          {
            "key": "Last 24 Hours",
            "from": "now-24h",
            "to": "now"
          }
        ],
        "keyed": true
      }
    }
  }
};

export default Query;
