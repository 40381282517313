import React, { useLayoutEffect } from 'react'
import '../Dashboard.css'
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import JsonData from "./Data.json"

export default function Map(props) {
    useLayoutEffect(() => {
        let root = am5.Root.new("mapdiv");
        let chart = root.container.children.push(
            am5map.MapChart.new(root, {
                projection:am5map.geoEqualEarth(),
                panY:"none",
                panX:"none",
                wheelY:"none",
                wheelX:"none"
            })
            
        );
        let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                exclude:["AQ"],
                valueField:"value",
                calculateAggregates:true,
                fill:am5.color("#2a4b6e"),
                //fill:am5.color("#63728a"),
                

            })
        );
        // chart.chartContainer.set("background", am5.RectanglePattern.new(root,{
        //     fill:am5.color("#1a1717"),
        //     fillOpacity:1
        // }))
        polygonSeries.set("heatRules", [{
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0xe3dc0b),
            max: am5.color(0xe30b0b),
            key: "fill"
          }]);
        // let data=JsonData.aggregations.groupByCountry.buckets;
        let data=props.res?.aggregations?.groupByCountry?.buckets;
        if(data) {
            data.forEach((item)=>{
                item.id=item.key;
                item.value=item.doc_count;
                // item.polygonsettings={
                //     fill:am5.color("#f00")
                // }
            })
            polygonSeries.data.setAll(data)
        }
        
        polygonSeries.mapPolygons.template.setAll({
            tooltipText:"{name}:{value}",
            //templateField:"polygonsettings"
        })
        // polygonSeries.data.setAll(data)
        //console.log(data);

        root.current = root;

        return () => {
            root.dispose();
        };
    })
    return (
        <div style={{ flexDirection: 'column', width: '100%' }}>
            <h3>Source Country Heatmap</h3>
            <div id="mapdiv" style={{width: "100%", height: "400px"}}></div>
        </div>        
    )
}
