import React from 'react'
//import JsonData from "./Data.json"
export default function CountryCount(props) {
    const Data=props.res;
    const DispData = Data?.aggregations?.groupByCountry?.buckets?.slice(0, 10).map((data) => {
        return (
            <tr>
                <td><span className={`fi fi-${data.key.toLowerCase()}`}></span>&nbsp;{data.key}</td>
                <td>{data.doc_count}</td>
            </tr>
            
        )
    })
    return (
        <>
            <table className='table table-responsive ' style={{backgroundColor: '#1d2939', color: "#ffffff" }} >
                <thead>
                    <tr>
                        <th colSpan={2}><p>Top 10 Source Countries</p></th>
                    </tr>
                    <tr>
                        <th>Country Code</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {DispData}
                </tbody>
            </table>
        </>
    )
}
