import React, { useState } from 'react'
import { Grid, TextField, Button } from '@mui/material'
import { Pagination } from '@mui/material';
// import './Network.css'
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import Error from "../Error/Error"
import Modall from "./Modall"

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import HPStatus from './HPStatus';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const Deploy = () => {

  const [honeypot, setHoneypot] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setsearchQuery] = useState(null);
  const [totalPages, settotalPages] = useState(10)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();


  // fetch the data of honeypot according to pagination 
  async function fetchHoneypot() {
    try {
      setLoading(true);
      const response = await fetch(`/configuration/list?page=${page}&limit=8`);
      const data = await response.json(); 
      setHoneypot(data.posts);
      settotalPages(data.totalPages);
      setLoading(false);
    } catch (err) {
      console.log(err);
      navigate("/");
    }

  }

  //search query for name of honeypot
  async function search() {
    setLoading(true);
    const response = await fetch(`/searchHoneypot/?page=${page}&limit=8&id=${searchQuery}`);
    const data = await response.json();
    data.posts.forEach(hp => (
      {...hp, status: ""}
    ));
    setHoneypot(data.posts);
    // console.log(data.totalPages);
    settotalPages(data.totalPages);
    // setLoading(true);
    setLoading(false);
  }

  // function find() {
  //   if (page !== 1) {
  //     setPage(1);
  //   } else {
  //     search();
  //   }
  // }

  //get the value from input box and set it to searchquery state 
  // function getData(e) {
  //   setsearchQuery(e.target.value);
  // }

  useEffect(() => {
    let pg = window.localStorage.getItem("page");
    if (!pg) {
      setPage(+pg);
      console.log(+pg);
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem("page", page);
    if (searchQuery !== null && searchQuery !== '') {
      search();
    }
    else {
      fetchHoneypot();
    }
  }, [page]);


  useEffect(() => {
    if (searchQuery === '' || searchQuery === null) {

      if (page === 1) {
        fetchHoneypot();
      }
      else {
        setPage(1);
      }
    }
  }, [searchQuery]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="netowrk">
        <div className="heading">
          <h2>Honeypots</h2>
        </div>

        <div className='search'>
          {/* <input type="text" name='name' className='searchInput' label='Search Server' placeholder='Search Server' onChange={getData} /> */}
          {/* <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <TextField className="searchInput" name='name' label='Search Server' placeholder='Search Server' variant="standard" size="small" onChange={getData} />
          </ThemeProvider>
          <Button onClick={find} ><SearchIcon fontSize="large" color="primary" /></Button> */}
          <Modall />
        </div>

        <section>
          {!loading ?
            <div>
              <table className="table table-hover table-responsive table-dark  mx-2 my-3">
                <thead>
                  <tr>
                    <th style={{width: '40%'}}>Name</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {honeypot.map((current, index) => (
                    <tr key={index}>
                      <td>{current.name.toUpperCase()}</td>
                      <td>{current.type}</td>
                      <td>{current.description}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            :
            <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '87vw', height: '' }}>
              <CircularProgress color="success" />
            </Grid>
          }

          {(honeypot.length === 0 && (!loading)) && <Error />}

        </section>
        {
          (honeypot.length !== 0 && (!loading)) &&
          <div className="pagination">
            <ThemeProvider theme={darkTheme}>
              <CssBaseline/>
              <Pagination
                page={page}
                size="large"
                count={totalPages}
                color="secondary"
                variant='outline'
                shape='rounded'
                onChange={(event, value) => setPage(value)}
              />
            </ThemeProvider>
          </div>

        }


      </div>
    </>
  )
}

export default Deploy