import { React, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Pagination } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Select, MenuItem, Modal, Button } from '@mui/material'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const AlertLog = () => {
  const [alert, setAlert] = useState([]);
  const [totalPages, settotalPages] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("All");
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const location = useLocation();
  const dt = location.state;

  useEffect(() => {
    fetchAlert()
  }, [page, type]);

  async function fetchAlert() {
    try {
      setIsLoading(true)
      const response = await fetch(`/alerts?page=${page}&limit=40&honeypot_type=${type}`);
      const data = await response.json();
      setAlert(data.posts);
      settotalPages(data.totalPages)
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  const handleChange = (e) => {
    setType(e.target.value);
    console.log(type);
    setPage(1);
  }

  // const handleExport = () => {
  //   var exportdata = new Blob([JSON.stringify(JSON.parse(selectedData?._source?.event?.original), null, 4)], { type: 'text/json' });
  //   var csvURL = window.URL.createObjectURL(exportdata);
  //   var tempLink = document.createElement('a');
  //   tempLink.href = csvURL;
  //   tempLink.setAttribute('download', `export-raw-event-${dt.ip}-${selectedData?._id}.json`);
  //   tempLink.click();
  // }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    background: '#222',
    border: '2px solid #000',
    boxShadow: 24,
    color: 'white',
    padding: '10px',
    borderRadius: '8px'
  };

  return (
    <>
      {isLoading ? (
        <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '87vw', height: '' }}>
          <CircularProgress color="success" />
        </Grid>
      ) : (
        <>
          <Modal
            open={open}
            onClose={() => { setSelectedData(null); setOpen(false); }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={style}>
              <div style={{ height: '10%' }} className="d-flex align-items-center justify-content-between">
                <div>
                  <h5><b>Event ID:</b> {selectedData?._id}</h5>
                  <h6><b>Category:</b> {selectedData?._source.event.kind.toUpperCase()}</h6>
                </div>
                {/* <Button color="primary" variant="contained" onClick={handleExport}><Download fontSize="large" />Export Log</Button> */}
              </div>
              <hr />
              <div style={{ height: '85%' }}>
                <h6>Raw Log</h6>
                <pre style={{ height: '95%', overflowY: 'auto' }}>
                  <code>
                    {selectedData && JSON.stringify(JSON.parse(selectedData?._source?.event?.original), null, 4)}
                  </code>
                </pre>
              </div>
            </div>
          </Modal>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Event Logs</h3>
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <Select
                id="select"
                value={type}
                label="Type"
                onChange={handleChange}
                // style={{ width: 100 }}
              >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'IT'}>IT</MenuItem>
                <MenuItem value={'ICS'}>ICS</MenuItem>
                <MenuItem value={'SCADA'}>SCADA</MenuItem>
              </Select>
            </ThemeProvider>
          </div>
          <table className="table table-hover table-responsive table-dark ">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Port</th>
                <th>Source IP</th>
                <th>Source Country Code</th>
                <th>Alert Name</th>
              </tr>
            </thead>
            <tbody>
              {
                alert.map((row, index) => (
                  <tr key={index} onClick={() => { setSelectedData(row.data); setOpen(true); }} >
                    <td>{new Date(row.data._source['@timestamp']).toUTCString()}</td>
                    <td>{row.data._source.destination.port}</td>
                    <td>{row.data._source.source.address}</td>
                    <td><span className={`fi fi-${row.data._source.source?.geo?.country_iso_code.toLowerCase()}`}></span>&nbsp;{row.data._source.source?.geo?.country_iso_code || '--'}</td>
                    <td>{row.name}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="pagination">
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <Pagination
                page={page}
                size="large"
                count={totalPages}
                color="secondary"
                variant='outline'
                shape='rounded'
                onChange={(event, value) => setPage(value)}
              />
            </ThemeProvider>
          </div>
        </>
      )
      }
    </>
  )
}

export default AlertLog