
import HomeIcon from "@mui/icons-material/Home"
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import PatternIcon from "@mui/icons-material/Pattern"
import DashboardIcon from "@mui/icons-material/Dashboard"
export const LeftbarData=[
    {
        id:1,
        title:'Home',
        path:'/homepage',
        icon:<HomeIcon/>
    },
    {
        id:2,
        title:'Honeypots',
        path:'/home',
        icon:<FormatListBulletedIcon/>
    },
    // {
    //     id:3,
    //     title:'Alert Log',
    //     path:'/alertlog',
    //     icon:<FmdBadIcon/>
    // }
]