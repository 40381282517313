import React from 'react';
import { Modal, TextField, Button, Box, StepLabel, Step, Stepper, MenuItem, FormControl, InputLabel, Select } from '@mui/material'
import { useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';

const steps = [
    'Honeypot'
];


const Modall = () => {

    // console.log(area.regions);

    const [state, setState] = useState(0);
    const [honeypotName, setHoneypotName] = useState(["First select type"]);

    const honeypotType = ['IT', 'ICS', 'SCADA'];
    const size = [1, 2, 3, 4];
    // const region = ['delhi', 'kanpur', 'kk', 'ludhiyana'];

    const nextModal = () => {
        setState(state + 1);
    }
    const BackServer = () => {
        setState(state - 1);
    }

    const [serverData, setServerData] = useState({
        name: "", type: "", description: "", script: ""
    });

    let name, value;
    const handleInput = (e) => {
        // console.log(e);
        name = e.target.name;
        value = e.target.value;
        setServerData({ ...serverData, [name]: value })
    }

    const PostServer = async (e) => {
        e.preventDefault();
        const { name, type, description, script } = serverData;
        const res = await fetch("/configuration", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name, type, description, script
            })
        });

        const data = await res.json();
        if (res.status === 400 || !data) {
            window.alert("Invalid Server");
        }
        else {
            window.alert("Server Added");
        }
        handleClose();
        setState(0);
        setServerData({});
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    async function fetchByType() {
        try {
          const response = await fetch(`/configuration?type=${serverData.type}`);
          const data = await response.json();
          let honeypotArray = []
          data.map((current, index)=>(
            honeypotArray.push(current.name)
          ))
          setHoneypotName(honeypotArray)
        } catch (err) {
          console.log(err);
        }
      }

    useEffect(() => {
        if(serverData.type){
            fetchByType()
        }
      }, [serverData.type])
    


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <div>
                <Button onClick={handleOpen}><AddIcon fontSize="large" /></Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={state} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        {state === 0 &&
                            <>
                                {/* <Grid align='center'> <h6>Add Server</h6> </Grid> */}

                                {/* <TextField className="type" name='type' label='Type' placeholder='Enter type' margin='normal' value={serverData.type} onChange={handleInput} fullWidth required /> */}
                                <FormControl margin='normal' fullWidth>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        name='type'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={serverData.type}
                                        label="Type"
                                        onChange={handleInput}
                                    >
                                        {honeypotType.map((type) => {
                                            return <MenuItem value={type} >{type}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <InputLabel id="demo-simple-select-label">Honeypot</InputLabel>
                                    <Select
                                        name='name'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={serverData.name}
                                        label="Honeypot"
                                        onChange={handleInput}
                                    >
                                        {honeypotName.map((type) => {
                                            return <MenuItem value={type} >{type}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField fullWidth id="fullWidth" className="description" name='description' label='Description' placeholder='Description' margin='normal' value={serverData.description} onChange={handleInput} />
                                <TextField fullWidth id="fullWidth" className="script" name='script' label='script' placeholder='Script' margin='normal' value={serverData.script} onChange={handleInput} />
                                <Button type='submit' color='primary' variant="contained" style={{width: '49%', marginRight:'1%' }} margin='normal' onClick={PostServer} >ADD</Button>
                            </>}
                    </Box>
                </Modal>
            </div>


        </>
    )
}

export default Modall