import { React, useEffect, useState } from "react";
import './Home.css'
import Count from "./HomeComponent/Count";
import Map from "./HomeComponent/Map";
import Bar from "./HomeComponent/Bar"
import AlertTable from "./HomeComponent/AlertTable";
import CountryCount from "./HomeComponent/CountryCount";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button } from '@mui/material'

const Home = () => {
  const [user, setUser] = useState();
  const [Sdate, setSdate] = useState();
  const [Edate, setEdate] = useState();
  const [prev, setPrev] = useState();
  const [next, setNext] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function fetchHoneypot() {
    try {
      setIsLoading(true)
      if (!Sdate && !Edate) {
        setIsLoading(false);
        return;
      }
      const response = await fetch(`/dashboardStates?start=${Sdate}&end=${Edate}`);
      const data = await response.json();
      setNext(data.nextCount)
      setPrev(data.prevCount)
      setUser(data.Data);
      setIsLoading(false)
    } catch (err) {
      console.log(err);
    }
  }

  function handlePrev() {
    var startdate = new Date(Sdate);
    startdate.setUTCHours(startdate.getUTCHours() - 1);
    var sdate = startdate.toUTCString()
    // console.log(sdate);
    setSdate(sdate)

    var enddate = new Date(Edate);
    enddate.setUTCHours(enddate.getUTCHours() - 1);
    var edate = enddate.toUTCString()
    setEdate(edate)
    // console.log(sdate, edate);
  }

  function handleNext() {
    var startdate = new Date(Sdate);
    startdate.setUTCHours(startdate.getUTCHours() + 1);
    var sdate = startdate.toUTCString()
    // console.log(sdate);
    setSdate(sdate)

    var enddate = new Date(Edate);
    enddate.setUTCHours(enddate.getUTCHours() + 1);
    var edate = enddate.toUTCString()
    setEdate(edate)
    // console.log(sdate, edate);
  }


  useEffect(() => {
    fetchHoneypot()
  }, [Edate, Sdate]);

  useEffect(() => {
    const edate = new Date();
    edate.setUTCMinutes(0);
    edate.setUTCSeconds(0);
    const endTime = edate.toUTCString();
    // console.log(endTime);
    setEdate(endTime)

    const sdate = new Date();
    sdate.setUTCHours(sdate.getUTCHours() - 1);
    sdate.setUTCMinutes(0);
    sdate.setUTCSeconds(0);
    const startTime = sdate.toUTCString();
    // console.log(startTime);
    setSdate(startTime)
  }, []);


  return (
    <>
      <div  style={{ border: '18px solid #1d2939', borderRadius: '24px' }} >
        <center>
          {Sdate}
          <Button style={{ color: 'white' }} disabled={!prev} onClick={handlePrev} ><ArrowCircleLeftIcon style={{ height: 40, width: 40, color: "#8838DD" }} /></Button>
          <Button style={{ color: 'white' }} disabled={!next} onClick={handleNext} ><ArrowCircleRightIcon style={{ height: 40, width: 40, color: "#8838DD" }} /></Button>
          {Edate}
        </center>
        {isLoading ? (
          <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '87vw', height: '78vh' }}>
            <CircularProgress color="success" />
          </Grid>
        ) : (
          <>
            <div className="row count_strip"  >
              <Count res={user} />
            </div>
            <div className="col-md chart" style={{backgroundColor: '#1d2939' }} >
              <Bar res={user} />
            </div>
            <div className="col-md chart" style={{backgroundColor: '#1d2939' }} >
              <Map res={user} />
            </div>
            <div className="row table1" >
              <AlertTable res={user} />
            </div>
            <div className="col-md table1">
              <CountryCount res={user} />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Home