import React, { useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
//import JsonData from "./Bar.json";

export default function Bar(props) {

    useLayoutEffect(() => {
        let root = am5.Root.new("chartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true
        }));
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);
        let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 0 });
        xRenderer.labels.template.setAll({
            rotation: -90,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15,
            fill: am5.color("#ffffff")
        });
        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "key",
            renderer: xRenderer,

            tooltip: am5.Tooltip.new(root, {})
        }));
        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "doc_count",
            sequencedInterpolation: true,
            categoryXField: "key",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{key_as_string}, {doc_count}"
            })
        }));

        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
        // series.columns.template.adapters.add("fill", function (fill, target) {
        //     return chart.get("colors").getIndex(series.columns.indexOf(target));
        // });
        series.columns.template.setAll({ "fill": am5.color("#fc5908") })
        series.columns.template.setAll({ "stroke": am5.color("#942003") })

        let yrenderer = yAxis.get("renderer");
        yrenderer.labels.template.setAll({
            fill: am5.color("#ffffff")
        })

        // series.columns.template.adapters.add("stroke", function (stroke, target) {
        //     return chart.get("colors").getIndex(series.columns.indexOf(target));
        // });

        // let data2 = JsonData.aggregations.barChartData.buckets;
        // let data2 = props?.res?.aggregations?.attackCount?.buckets?.['Last Hour'].doc_count;
        // if(data2 && data2.length == 25) {
        //     data2.shift();
        // }
        // if(data2) {
        //     data2.forEach((data) => {
        //         let temp = new Date(data.key);
        //         data.key_as_string = ((temp.getHours() < 10) ? "0" : "") + temp.getHours() + ":" + ((temp.getMinutes() < 10) ? "0" : "") + temp.getMinutes()

        //     })

        //     xAxis.data.setAll(data2);
        //     series.data.setAll(data2);
        // }

        let myArray = []
        async function barData() {
            try {
                if (props.res) {
                    for (let i = 0; i < props.res.length; i++) {
                        let myobj = {}
                        let data = props?.res[i]?.aggregations?.attackCount?.buckets?.['Last Hour'].doc_count;
                        if (data !== undefined) {
                            myobj.doc_count = data;
                        } else {
                            myobj.doc_count = 0;
                        }

                        // myobj.key_as_string = i
                        let name = props?.res[i]?.name
                        myobj.key_as_string = name
                        myobj.key = `${i}`
                        myArray.push(myobj)
                        // console.log(data);
                    }
                    // console.log(myArray);
                    xAxis.data.setAll(myArray);
                    series.data.setAll(myArray);
                }
            } catch (err) {
                console.log(err);
            }
        }
        barData()

        root.current = root;

        return () => {
            root.dispose();
        };
    }, [props]);


    return (
        <div style={{ flexDirection: 'column', width: '100%' }}>
            <h3>Event Trends - Last 1 Hour</h3>
            <div id='chartdiv' style={{ width: "100%", height: "400px" }}></div>
        </div>
    )
}
