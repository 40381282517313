import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import HomeIcon from "@mui/icons-material/Home"
import { useNavigate } from 'react-router-dom';
import {Button, Toolbar} from '@mui/material';
import PatternIcon from "@mui/icons-material/Pattern"
import DashboardIcon from "@mui/icons-material/Dashboard"
import "../../../../index.css"


export default function BottomBar() {
  const navigate=useNavigate()
  const [value, setValue] = React.useState(0);
  

  return (
    <Box sx={{zIndex:111111}} >
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,  }} elevation={3}>
        <BottomNavigation         
         sx={{background:'var(--sidebarcolor)'}}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
         <Button onClick={()=> navigate('/login/About')} sx={{color:'var(--buttoncolor)',"&:hover": {borderRadius:1,backgroundColor: '#f3f0f030'}}}>
            {<DashboardIcon color='white'/>}
        </Button>
          <Toolbar/>
         <Button onClick={()=> navigate('/login/Server')} sx={{color:'var(--buttoncolor)',"&:hover": {borderRadius:1,backgroundColor: '#f3f0f030'}}}>
          {<PatternIcon color='white'/>}
          </Button>
         <Toolbar/>
         <Button onClick={()=> navigate('/login/Home')} sx={{color:'var(--buttoncolor)',"&:hover": {borderRadius:1,backgroundColor: '#f3f0f030'}}}>
          {<HomeIcon color='white'/>}
          </Button>
         
        </BottomNavigation>
      </Paper>
    </Box>
  );
}

