const ExportQuery = {
	"size": 1000,
  "from": 1000,
  "query": { 
    "bool": { 
      "filter": [ 
        { 
          "range": { 
            "@timestamp": { 
              "gte": "now-24h" 
            } 
          } 
        },
        {
          "terms": {
            "event.kind": ["alert", "event"]
          }
        }
      ]
    } 
  },
  "sort": [
    {
      "@timestamp": {
        "order": "desc"
      }
    }
  ]
};

export default ExportQuery;